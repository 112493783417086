import React from 'react';
import './Navigation.css'; // Стилі для навігації
import VacantionsIcon from '../../assets/images/navicon-1.png';
import DocumentIcon from '../../assets/images/navicon-2.png'
import MenuIcon from '../../assets/images/navicon-3.png';
import VacationsActiveIcon from '../../assets/images/navicon-1-active.png'
import DocumentActiveIcon from '../../assets/images/navicon-2-active.png';

// Компонент навігації
export default function Navigation({ currentContent, onNavigate }) {
    return (
        <div className="navigation">
            <div
                className={`nav-btn ${currentContent === "vacantions" ? "active" : ""}`}
                onClick={() => onNavigate("vacantions")}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => e.key === 'Enter' && onNavigate("vacantions")} // Для підтримки доступності
            >
                <img 
                    src={currentContent === "vacantions" ? VacationsActiveIcon : VacantionsIcon} 
                    alt="vacantions" 
                />
                <span>Вакансії</span>
            </div>
            <div
                className={`nav-btn ${currentContent === "documents" ? "active" : ""} documents`}
                onClick={() => onNavigate("documents")}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => e.key === 'Enter' && onNavigate("documents")} // Для підтримки доступності
            >
                <img 
                    src={currentContent === "documents" ? DocumentActiveIcon : DocumentIcon}
                    className='documents-icon'
                    alt="Documents" 
                />
                <span>Мій документ</span>
            </div>
            <div
                className={`nav-btn ${currentContent === "menu" ? "active" : ""} menu`}
                onClick={() => onNavigate("menu")}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => e.key === 'Enter' && onNavigate("menu")} // Для підтримки доступності
            >
                <img 
                    src={currentContent === "menu" ? MenuIcon : MenuIcon} 
                    alt="Settings" 
                />
                <span>Меню</span>
            </div>
        </div>
    );
}
