// src/app/QRScannerPage/QRScannerPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom'; // Для навігації
import Webcam from 'react-webcam';
import styles from './QRScannerPage.module.css'; // Підключення стилів

export default function QRScannerPage() {
    const navigate = useNavigate(); // Хук для повернення на попередню сторінку

    // Обробник кнопки "Хрестик"
    const handleBack = () => {
        navigate(-1); // Повертає на попередню сторінку
    };

    return (
        <div className={styles.qrScannerPage}>
            {/* Верхній блок із кнопкою закриття */}
            <div className={styles.header}>
                <button onClick={handleBack} className={styles.closeButton}>
                    &times;
                </button>
            </div>

            {/* Відео з камери */}
            <div className={styles.scannerContainer}>
                <Webcam
                    audio={false}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{
                        facingMode: 'environment', // Використовує камеру на задній панелі
                    }}
                    className={styles.webcam}
                />
                <div className={styles.overlay}></div> {/* Рамка */}
            </div>

            {/* Текст підказки */}
            <p className={styles.scanPrompt}>
                Наведіть рамку на QR-код, який хочете відсканувати.
            </p>
        </div>
    );
}
