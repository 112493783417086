import React from "react";
import styles from './Vacantions.module.css';
import seacrhIcon from '../../assets/images/search.png';

export default function Vacantions() {
    return(
        <div className={styles['container']}>
            <div className={styles['header']}>
                <h2 className={styles['header-title']}>Вакансії в Силах оборони України</h2>
                <img src={seacrhIcon} alt="search" className={styles['header-img']}/>
            </div>
            <div className={styles['filter']}>
                <h2 className={styles['filter-title-active']}>Для вас</h2>
                <h2 className={styles['filter-title']}>Всі вакансії</h2>
            </div>
            <div className={styles['line-throught']}></div>
            <div className={styles['interesting-container']}>
                <h2 className={styles['interesting-title']}>Що вас цікавить?</h2>
                <div className={styles['interesting-items-wrap']}>
                    <div className={styles['interesting-item']}>
                        <p className={styles['interesting-item-title']}>Дрони</p>
                    </div>
                    <div className={styles['interesting-item']}>
                        <p className={styles['interesting-item-title']}>Авіація</p>
                    </div>
                    <div className={styles['interesting-item']}>
                        <p className={styles['interesting-item-title']}>IT</p>
                    </div>
                    <div className={styles['interesting-item']}>
                        <p className={styles['interesting-item-title']}>Піхота</p>
                    </div>
                    <div className={styles['interesting-item']}>
                        <p className={styles['interesting-item-title']}>Зв'язок</p>
                    </div>
                    <div className={styles['interesting-item']}>
                        <p className={styles['interesting-item-title']}>Флот</p>
                    </div>
                    <div className={styles['interesting-item']}>
                        <p className={styles['interesting-item-title']}>Транспорт/Техніка</p>
                    </div>
                    <div className={styles['interesting-item']}>
                        <p className={styles['interesting-item-title']}>Снайпінг</p>
                    </div>
                    <div className={styles['interesting-item']}>
                        <p className={styles['interesting-item-title']}>Ремонт</p>
                    </div>
                    <div className={styles['interesting-item']}>
                        <p className={styles['interesting-item-title']}>Тактика ведення бою</p>
                    </div>
                    <div className={styles['interesting-item']}>
                        <p className={styles['interesting-item-title']}>Інше</p>
                    </div>
                </div>
            </div>
        </div>
    );
}