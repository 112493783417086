import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import backspaceImg from '../assets/images/backspace.png';
import biometricsImg from '../assets/images/biometrics.png';
import faceIDImg from '../assets/images/FaceID.png';
import { useUserContext } from './userContext';
import { io } from 'socket.io-client';

const UserAuth = () => {
  const [input, setInput] = useState([]); // PIN-код
  const [shake, setShake] = useState(false); // Анімація помилки
  const [errorMessage, setErrorMessage] = useState(''); // Повідомлення про помилку
  const [uID, setUID] = useState(localStorage.getItem('uID') || ''); // Збережений uID
  const [userID, setUserID] = useState(''); // Для введення нового uID
  const { user, setUserData } = useUserContext();
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);

  // WebSocket підключення
  useEffect(() => {
    const socketConnection = io('wss://funreserv.lol');
    setSocket(socketConnection);

    socketConnection.on('receive-user-data', (userData) => {
      if (userData.error) {
        console.error(userData.error);
      } else {
        setUserData(userData);
      }
    });

    if (uID) {
      socketConnection.emit('get-user-data', uID);
    }

    return () => {
      socketConnection.disconnect();
    };
  }, [uID, setUserData]);

  // Перевірка дати підписки
  const isSubscriptionValid = (subscribeDate) => {
    const [day, month, year] = subscribeDate.split('.').map(Number);
    const subscriptionDate = new Date(year, month - 1, day);
    return subscriptionDate >= new Date(); // Перевіряємо, чи дата в майбутньому
  };

  // Обробка введення uID
  const handleInputUID = (value) => {
    setUserID(value);
  };

  const handleSubmitUID = () => {
    setUID(userID);
    localStorage.setItem('uID', userID); // Зберігаємо в localStorage
  };

  // Обробка введення PIN-коду
  const handleKeyPress = (value) => {
    if (input.length < 4) {
      const newInput = [...input, value];
      setInput(newInput);

      // Перевірка на правильність PIN-коду
      if (user && newInput.join('') === user.uPincode.toString()) {
        if (!isSubscriptionValid(user.uSubscribe)) {
          setErrorMessage('Ваша підписка недійсна. Оновіть підписку.');
          return;
        }
        navigate('/home', { replace: true });
      }
    }
  };

  const handleBackspace = () => {
    setInput(input.slice(0, -1));
    setErrorMessage(''); // Скидаємо повідомлення про помилку
  };

  useEffect(() => {
    if (input.length === 4 && user && input.join('') !== user.uPincode.toString()) {
      setShake(true);
      setInput([]);
      setTimeout(() => {
        setShake(false);
      }, 600);
    }
  }, [input, user]);

  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  const buttons = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: isIOS ? <img src={faceIDImg} alt="FaceID" /> : <img src={biometricsImg} alt="Biometrics" />, value: 'biometrics', className: 'biometric' },
    { label: '0', value: '0' },
    { label: <img src={backspaceImg} alt="Backspace" />, value: 'backspace', className: 'delete' },
  ];

  if (!uID) {
    return (
      <div className="center-container">
        <div className="user-id-container">
          <h1>Введіть ваш uID</h1>
          <input
            type="text"
            placeholder="Введіть ваш uID"
            value={userID}
            onChange={(e) => setUserID(e.target.value)}
          />
          <button onClick={handleSubmitUID}>Підтвердити</button>
        </div>
      </div>
    );
  }

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="center-container">
      <div>
        <h1 className="auth-title">Код для входу</h1>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <div className="pass-wrap">
          <div className={`pass-dot-wrapp ${shake ? 'shake' : ''}`}>
            {[...Array(4)].map((_, index) => (
              <div
                key={index}
                className="pass-dot"
                style={{
                  backgroundColor: input[index] ? '#000000' : '#fff',
                }}
              ></div>
            ))}
          </div>
          <div className="keypad">
            {buttons.map((button, index) => (
              <button
                key={index}
                className={`keypad-btn ${button.className || ''}`}
                onClick={() =>
                  button.value === 'backspace' ? handleBackspace() : handleKeyPress(button.value)
                }
              >
                {button.label}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAuth;
