import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Добавляем необходимые компоненты
import Home from './app/Home/Home';
import { UserProvider } from './app/userContext';
import UserAuth from './app/userAuth'; // Импортируем компонент UserAuth
import QRScannerPage from './app/QRScannerPage/QRScannerPage';
import SplashIcon from './assets/images/logo-splash.png';
import './App.css';

const App = () => {
  const [isWorking, setIsWorking] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Функция для изменения цвета темы
    const setThemeColor = (color) => {
      const themeMetaTag = document.querySelector('meta[name="theme-color"]');
      if (themeMetaTag) {
        themeMetaTag.setAttribute('content', color);
      }
    };

    // Установить цвет темы во время splash screen
    setThemeColor('#23221e'); // Светлый цвет во время загрузки

    // Имитация загрузки данных (например, после 3 секунд)
    const timer = setTimeout(() => {
      setIsLoading(false);
      // После загрузки, изменить цвет на темный
      setThemeColor('#e5e3d2'); // Темный цвет после завершения загрузки
    }, 3000); // 3 секунды, для примера

    return () => clearTimeout(timer); // Очистить таймер при размонтировании компонента
  }, []);

  useEffect(() => {
    const preventLandscape = () => {
      if (window.innerHeight < window.innerWidth) {
        document.body.style.transform = 'rotate(0deg)';
      }
    };

    window.addEventListener('orientationchange', preventLandscape);

    return () => {
      window.removeEventListener('orientationchange', preventLandscape);
      document.body.style.transform = '';
    };
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    const isInWebAppiOS = window.navigator.standalone === true;
    const isInWebAppChrome = window.matchMedia('(display-mode: standalone)').matches;

    if (!isMobile || (!isInWebAppiOS && !isInWebAppChrome)) {
      setIsWorking(false);
    }
  }, []);

  if (!isWorking) {
    return (
      <div style={{ padding: '10vh 2vw', textAlign: 'center', color: '#000000', fontFamily: 'e-UkraineHead-Regular', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <h2>@fake_reserv</h2>
        <p>Сайт доступний тільки для мобільних пристроїв.</p>
        <p>Натисніть на три крапки в браузері і додайте цю сторінку на головний екран.</p>
        <h2>@fake_reserv</h2>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="splash-screen">
        <img src={SplashIcon} alt='logo'/>
        <h2>Міністерство<br />оборони<br />України</h2>
      </div>
    );
  }

  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/qr-scanner" element={<QRScannerPage />} />
          <Route path="/" element={<UserAuth />} />
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;
