import React, { useState } from 'react';
import styles from './FullInfoModal.module.css'; // Використовуємо CSS-модулі
import HeaderLogo from '../../assets/images/doc-logo.png';
import Check from '../../assets/images/check.png';
import { useUserContext } from '../userContext';

export default function FullInfoModal({ isOpen, onClose }) {
    const {user} =useUserContext()
    const [isClosing, setIsClosing] = useState(false);
    const currentTime = new Date().toLocaleString('uk-UA', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });
    
    const currentDate = new Date().toLocaleDateString('uk-UA', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    
    // Concatenate the current time and date
    const formattedDate = `${currentTime} | ${currentDate}`;

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            onClose();
            setIsClosing(false);
        }, 300);
    };

    if (!isOpen && !isClosing) {
        return null;
    }

    return (
        <div className={styles['full-info-modal']}>
            <div className={styles['full-info-overlay']} onClick={handleClose}></div>
            <div
                className={`${styles['full-modal-content']} ${
                    isClosing ? styles['slide-down'] : styles['slide-up']
                }`}
            >
                <div
                    className={styles['modal-drag-bar']}
                    onTouchMove={handleClose}
                    onMouseDown={handleClose}
                ></div>
                <div className={styles['full-modal-items']}>
                    <div className={styles['modal-container']}>
                        <div className={styles['modal-top']}>
                            <h2 className={styles['modal-top-title']}>Військово-обліковий документ</h2>
                            <img src={HeaderLogo} alt='header-logo'/>
                        </div>
                    </div>
                    </div>
                <div className={styles['marquee-wrap']}>
                    <div className={styles['marquee']}>
                        <p className={styles['marquee-text']}>На обліку · Документ оновлено о {formattedDate}</p>
                        <p className={styles['marquee-text']}>На обліку · Документ оновлено о {formattedDate}</p>
                        <p className={styles['marquee-text']}>На обліку · Документ оновлено о {formattedDate}</p>
                        <p className={styles['marquee-text']}>На обліку · Документ оновлено о {formattedDate}</p>
                        <p className={styles['marquee-text']}>На обліку · Документ оновлено о {formattedDate}</p>
                    </div>
                </div>
                <div className={styles['modal-container']}>
                    <div className={styles['info-item']}>
                        <div className={styles['info-item-container']}>
                            <div className={styles['info-item-wrap-name']}>
                                <h2 className={styles['info-title-name']}>{user.uSurname} {user.uName}</h2>
                                <h2 className={styles['info-title-name']}>{user.uFather}</h2>
                            </div>
                            <div className={styles['info-status']}>
                                <h2 className={styles['info-status']}>На обліку</h2>
                            </div>
                        </div>
                        <div className={styles['line-throught']}></div>
                        <div className={styles['info-item-container']}>
                            <div className={styles['info-wrapper']}>
                                <div className={styles['info-wrapper-item']}>
                                    <div className={styles['info-wrapper-left']}>
                                        <h3>Дата народження:</h3>
                                    </div>
                                    <div className={styles['info-wrapper-right']}>
                                        <h3>{user.uDOB}</h3>
                                    </div>
                                </div>
                                <div className={styles['info-wrapper-item']}>
                                    <div className={styles['info-wrapper-left']}>
                                        <h3>РНОКПП:</h3>
                                    </div>
                                    <div className={styles['info-wrapper-right']}>
                                        <h3>{user.uRNOKPP}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles['info-item']}>
                        <div className={styles['info-item-container']}>
                            <div className={styles['info-wrapper']}>
                                    <div className={styles['info-wrapper-item']}>
                                        <div className={styles['info-wrapper-left']}>
                                            <h3>{user.uReservType === 0 ? 'Бронювання до': 'Відстрочка до'}:</h3>
                                            </div>
                                            <div className={styles['info-wrapper-right']}>
                                                <h3>{user.uBronDate}</h3>
                                            </div>
                                    </div>
                            </div>
                        </div>
                        <div className={styles['line-throught']}></div>
                        <div className={styles['info-item-container']}>
                            <div className={styles['info-wrapper']}>
                            <div className={styles['info-wrapper-left-second']}>
                                        <h3>{user.uReservType === 0 ? 'Тип бронювання': 'Тип відстрочки'}:</h3>
                                        <h3>{user.uBronType}</h3>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles['info-item']}>
                        <div className={styles['info-item-container']}>
                            <div className={styles['info-wrapper']}>
                                <div className={styles['info-wrapper-item']}>
                                    <div className={styles['info-wrapper-left']}>
                                        <h3>Постанова ВЛК:</h3>
                                    </div>
                                    <div className={styles['info-wrapper-right']}>
                                        <h3>{user.uPostanova}</h3>
                                    </div>
                                </div>
                                <div className={styles['info-wrapper-item']}>
                                    <div className={styles['info-wrapper-left']}>
                                        <h3>Дата ВЛК:</h3>
                                    </div>
                                    <div className={styles['info-wrapper-right']}>
                                        <h3>{user.uVLKDate}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles['info-item']}>
                        <div className={styles['info-item-container']}>
                            <div className={styles['info-wrapper']}>
                                <div className={styles['info-wrapper-item']}>
                                    <div className={styles['info-wrapper-left-second']}>
                                        <h3>ТЦК та СП:</h3>
                                        <h3>{user.uTCC}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles['line-throught']}></div>
                        <div className={styles['info-item-container']}>
                            <div className={styles['info-wrapper']}>
                                <div className={styles['info-wrapper-item']}>
                                    <div className={styles['info-wrapper-left']}>
                                        <h3>Звання:</h3>
                                    </div>
                                    <div className={styles['info-wrapper-right']}>
                                        <h3>{user.uRank}</h3>
                                    </div>
                                </div>
                                <div className={styles['info-wrapper-item']}>
                                    <div className={styles['info-wrapper-left']}>
                                        <h3>ВОС:</h3>
                                    </div>
                                    <div className={styles['info-wrapper-right']}>
                                        <h3>{user.uVOC}</h3>
                                    </div>
                                </div>
                                <div className={styles['info-wrapper-item']}>
                                    <div className={styles['info-wrapper-left-second']}>
                                            <h3>Категорія обліку:</h3>
                                            <h3>{user.uMilitaryStatus}</h3>
                                        </div>
                                </div>
                                <div className={styles['info-wrapper-item']}>
                                    <div className={styles['info-wrapper-left-second']}>
                                        <h3>{user.uRozdil}</h3>
                                    </div>
                                </div>
                                <div className={styles['info-wrapper-item']}>
                                    <div className={styles['info-wrapper-left-second']}>
                                        <h3>Унікальний номер:</h3>
                                        <h3>{user.uUniqueNumber}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles['info-item']}>
                        <div className={styles['info-item-container']}>
                            <div className={styles['info-wrapper']}>
                            <div className={styles['info-wrapper-item']}>
                                    <div className={styles['info-wrapper-left-second']}>
                                        <h3>Телефон:</h3>
                                        <h3>{user.uTelephone}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className={styles['info-wrapper-item']}>
                                <div className={styles['info-wrapper-left-second']}>
                                    <h3>Email:</h3>
                                    <h3>{user.uEmail}</h3>
                                </div>
                            </div>
                            <div className={styles['info-wrapper-item']}>
                                <div className={styles['info-wrapper-left-second']}>
                                    <h3>Адреса проживання:</h3>
                                    <h3>{user.uAddress}</h3>
                                </div>
                            </div>
                        </div>
                        <div className={styles['line-throught']}></div>
                        <div className={styles['info-item-container']}>
                            <div className={styles['info-wrapper']}>
                            <div className={styles.documentCheck}>
                                    <img src={Check} alt="Check" />
                                    <p>Дані уточнено вчасно</p>
                                </div>
                            <div className={styles['info-wrapper-item']}>
                                    <div className={styles['info-wrapper-left-second']}>
                                        <h3>Дата уточнення даних:</h3>
                                    </div>
                                    <div className={styles['info-wrapper-right']}>
                                        <h3>{user.uDataUpdateDate}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
