import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoIMG from '../../assets/images/logo.png';
import QRIcon from '../../assets/images/qr-icon.png';
import DocLogo from '../../assets/images/doc-logo.png';
import Check from '../../assets/images/check.png';
import MoreInfoIMG from '../../assets/images/more-info.png';
import MoreInfoPress from '../MoreInfo/MoreInfoPress';
import FullInfoModal from '../FullInfo/FullInfoModal';
import qrCode from '../../assets/images/qrCode.png'
import { useUserContext } from '../userContext';

export default function DocumentContent() {
    const { user, setUserData } = useUserContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFullInfoOpen, setIsFullInfoOpen] = useState(false);
    const [isQRCodeShow, setIsQRCodeShow] = useState(true);

    const navigate = useNavigate();

    const handleOpenModal = () => {
        setIsModalOpen(true);
        setIsQRCodeShow(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenFullInfo = () => {
        setIsFullInfoOpen(true);
        handleCloseModal();
    };

    const handleCloseFullInfo = () => {
        setIsFullInfoOpen(false);
    };

    const handleQRCodeToggle = () => {
        setIsQRCodeShow((prev) => !prev);
    };

    const handleScanButtonClick = () => {
        navigate('/qr-scanner');
    };

    const currentTime = new Date().toLocaleString('uk-UA', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    });

    const currentDate = new Date().toLocaleDateString('uk-UA', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    // Додаємо 7 днів до поточної дати
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 7);

    const formattedFutureDate = futureDate.toLocaleDateString('uk-UA', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });

    const formattedDate = `${currentTime} | ${currentDate}`;

    return (
        <div>
            {/* Хедер */}
            <div className="container">
                <div className="header">
                    <img src={LogoIMG} alt="logo" className="headerLogo" />
                    <div className="header-scan" onClick={handleScanButtonClick}>
                        <p className="header-scan-text">Сканувати документ</p>
                        <img src={QRIcon} alt="QR Icon" className="header-scan-img" />
                    </div>
                </div>
            </div>

            {/* Контент документа */}
            <div className={isQRCodeShow ? "document-section" : "parent-section"} onClick={handleQRCodeToggle}>
            {isQRCodeShow ? (
                <>
                    {/* Контейнер документа */}
                    <div className="document-container">
                        <div className="document-section-top">
                            <div className="document-section-top-top">
                                <p className="document-section-top-title">Військово-обліковий документ</p>
                                <img src={DocLogo} alt="Doc Logo" />
                            </div>
                            <div className="document-section-subtop">
                                <div className="document-check">
                                    <img src={Check} alt="Check" />
                                    <p>Дані уточнено вчасно</p>
                                </div>
                                <div className="document-section-data-wrap">
                                    <div className="document-section-data">
                                        <p className="document-section-data-title">Дата народження:</p>
                                        <p className="document-section-data-subtitle">{user.uDOB}</p>
                                    </div>
                                    <div className="document-section-data">
                                        <p className="document-section-data-title">{user.uReservType === 0 ? 'Бронювання до': 'Відстрочка до'}:</p>
                                        <p className="document-section-data-subtitle">{user.uBronDate}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Marquee окремо від документа */}
                    <div className="marquee-wrap">
                        <div className="marquee">
                            <p className="marquee-text">На обліку · Документ оновлено о {formattedDate}</p>
                            <p className="marquee-text">На обліку · Документ оновлено о {formattedDate}</p>
                            <p className="marquee-text">На обліку · Документ оновлено о {formattedDate}</p>
                            <p className="marquee-text">На обліку · Документ оновлено о {formattedDate}</p>
                            <p className="marquee-text">На обліку · Документ оновлено о {formattedDate}</p>
                        </div>
                    </div>

                    {/* Інформація про військовозобов'язаного */}
                    <div className="document-container">
                        <p className="subbottom-title">{user.uMilitaryStatus}</p>
                        <div className="document-section-bottom-wrap">
                            <div className="document-section-bottom-wrap-left">
                                <p className="document-section-bottom-wrap-left__title">{user.uSurname}</p>
                                <p className="document-section-bottom-wrap-left__title">{user.uName}</p>
                                <p className="document-section-bottom-wrap-left__title">{user.uFather}</p>
                            </div>
                            <div className="document-section-bottom-wrap-right">
                                <img
                                    src={MoreInfoIMG}
                                    alt="More Info"
                                    onClick={handleOpenModal}
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className='qr-conatiner'>
                    <h3 className='qr-code-title'>Код дійсний до: {formattedFutureDate}</h3>
                    <img src={qrCode} alt='qr'/>
                </div>
            )}
        </div>


            {/* Модальне вікно MoreInfo */}
            <MoreInfoPress
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onOpenFullInfo={handleOpenFullInfo}
            />

            {/* Модальне вікно FullInfo */}
            <FullInfoModal
                isOpen={isFullInfoOpen}
                onClose={handleCloseFullInfo}
            />
        </div>
    );
}
