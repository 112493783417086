import React, { useState } from "react";
import Navigation from '../Navigation/Navigation'; // Імпортуємо навігацію
import DocumentContent from '../Document/DocumentContent'; // Імпортуємо контент для документа
import Menu from '../Menu/Menu'
import './Home.css'
import Vacantions from "../Vacantions/Vacantions";

export default function Home() {
    const [currentContent, setCurrentContent] = useState("documents");

    // Функція для обробки переходів в навігації
    const handleNavigation = (contentType) => {
        setCurrentContent(contentType);
    };

    return (
        <div>
            {/* Навігація */}
            <Navigation currentContent={currentContent} onNavigate={handleNavigation} />

            {/* Вибір контенту в залежності від поточної навігації */}
            <div className="second-container">
                {currentContent === "documents" && <DocumentContent />}
                {currentContent === "menu" && <Menu />}
                {currentContent === "vacantions" && <Vacantions />}
            </div>
        </div>
    );
}
