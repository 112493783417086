import React from "react";
import styles from './Menu.module.css';
import { useUserContext } from '../userContext';
import NotificationsIcon from '../../assets/images/notifications.png';
import QuestionsIcon from '../../assets/images/questions.png';
import ReportIcon from '../../assets/images/report.png';
import SessionsIcon from '../../assets/images/sessions.png';
import SettingsIcon from '../../assets/images/settings.png';
import ExitIcon from '../../assets/images/exit.png';

export default function Menu(){
    const {user} =useUserContext()
    return(
        <div className={styles['menu-container']}>
            <h2 className={styles['menu-title']}>Вітаємо,<br />{user.uName}</h2>
            <div className={styles['menu-items-wrap']}>
                <div className={styles['menu-item']}>
                    <img src={NotificationsIcon} alt="Notifications"/>
                    <p>Cповіщення</p>
                </div>
                <div className={styles['menu-item']}>
                    <img src={QuestionsIcon} alt="Questions"/>
                    <p>Питання та відповіді</p>
                </div>
                <div className={styles['menu-item']}>
                    <img src={ReportIcon} alt="Report"/>
                    <p>Виправити дані онлайн</p>
                </div>
                <div className={styles['menu-item']}>
                    <img src={SessionsIcon} alt="Sessions" style={{width: 24, marginLeft: 3}}/>
                    <p>Активні сесії</p>
                </div>
                <div className={styles['menu-item']}>
                    <img src={SettingsIcon} alt="settings"/>
                    <p>Налаштування</p>
                </div>
                <div className={styles['menu-item']}>
                    <img src={ExitIcon} alt="Exit"/>
                    <p>Вийти</p>
                </div>
                <div className={styles['line-throught']}></div>
                <div className={styles['bottom-btns']}>
                    <p className={styles['bottom-btn']}>Копіювати номер пристрою</p>
                    <p className={styles['bottom-btn']}>Служба підтримки</p>
                    <p className={styles['bottom-text']}>Версія застосунку: 2.2.1.15603</p>
                </div>
            </div>
        </div>
    );
}