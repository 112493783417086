import React, { useState } from 'react';
import styles from './MoreInfoPress.module.css'; // Використовуємо CSS-модулі
import RefreshIcon from '../../assets/images/refresh.png';
import VidstrochkaIcon from '../../assets/images/vidstrochka.png';
import InfoIcon from '../../assets/images/info.png';
import PdfIcon from '../../assets/images/pdf.png';
import ReportIcon from '../../assets/images/report.png';

export default function MoreInfoPress({ isOpen, onClose, onOpenFullInfo }) {
    const [isClosing, setIsClosing] = useState(false);

    const handleDragDown = () => {
        setIsClosing(true);

        // Викликаємо onClose після завершення анімації
        setTimeout(() => {
            onClose();
            setIsClosing(false); // Скидаємо стан для наступного відкриття
        }, 300); // Тривалість анімації
    };

    const handleOpenFullInfo = () => {
        onOpenFullInfo(); // Викликаємо функцію для відкриття FullInfoModal
    };

    if (!isOpen && !isClosing) {
        return null;
    }

    return (
        <div className={styles['more-info-modal']}>
            {/* Напівпрозорий фон */}
            <div className={styles['modal-overlay']} onClick={handleDragDown}></div>

            {/* Модальне вікно */}
            <div
                className={`${styles['modal-content']} ${
                    isClosing ? styles['slide-down'] : styles['slide-up']
                }`}
            >
                {/* Полоска для свайпу */}
                <div
                    className={styles['modal-drag-bar']}
                    onTouchMove={handleDragDown} // Для мобільних пристроїв
                    onMouseDown={handleDragDown} // Для миші
                ></div>

                {/* Контент */}
                <div className={styles['modal-items']}>
                    <div className={styles['modal-item']} onClick={handleOpenFullInfo}>
                        <img src={InfoIcon} alt="info" />
                        <p>Повна інформація</p>
                    </div>
                    <div className={styles['modal-item']}>
                        <img src={PdfIcon} alt="pdf" />
                        <p>Завантажити PDF</p>
                    </div>
                    <div className={styles['modal-item']}>
                        <img src={RefreshIcon} alt="refresh" />
                        <p>Оновити документ</p>
                    </div>
                    <div className={styles['modal-item']}>
                        <img src={VidstrochkaIcon} alt="vidstrochka" />
                        <p>Подати запит на відстрочку</p>
                    </div>
                    <div className={styles['modal-item']}>
                        <img src={ReportIcon} alt="report" />
                        <p>Повідомити про проблему</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
